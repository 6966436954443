import React from 'react';
// import PropTypes from 'prop-types';
// import { navigate } from 'gatsby';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import { Layout } from 'containers';
import { SignInForm } from 'layouts';
import { BASE_URL } from 'config';
import { mapStateToProps } from 'utilities';

const SignInPage = () => (
  <Layout page="user/sign-in">
    <Helmet
      title="Sign In to Manage Your Dream New Zealand Vacation | Haka Tours, NZ"
      meta={[
        {
          name: 'description', content: `Access your Haka Tours account and favourites by signing in with us.'
          You'll be on your way to the best New Zealand tour as soon as possible.`,
        },
        { name: 'og:title', content: 'Sign In to Manage Your Dream New Zealand Vacation | Haka Tours, NZ' },
        {
          name: 'og:description', content: `Access your Haka Tours account and favourites by signing in with us.'
          You'll be on your way to the best New Zealand tour as soon as possible.`,
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/user/sign-in/` }]}
    />

    <SignInForm />
  </Layout>
);

// SignInPage.propTypes = {
//   user: PropTypes.object.isRequired,
// };

export default connect(mapStateToProps)(SignInPage);
